import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Col, Container, Row} from "react-bootstrap";
import PageHeaderBlock from "../../components/PageHeaderBlock";
import LastProjects from "../../components/portfolio/LastProjects";
import Breadcrumbs from "../../components/Breadcrumbs";
import LightboxGallery from "../../components/LightboxGallery";

export default function Prosteklo(query) {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('portfolioProSteklo')} description={t('portfolioProSteklo')} />
            <PageHeaderBlock h1="portfolioProSteklo" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="portfolioPageWord" firstChildUrl={'/portfolio'} secondChild="portfolioProSteklo" />
                <Row className="my-3 my-xl-4 my-xxl-5 pb-xl-3 pb-xxl-4">
                    <Col md={7} xxl={9}>
                        <LightboxGallery images={query.data.allPortfolioDataJson.nodes[0].images} alt={t('portfolioProSteklo')} isPortfolio={true} />
                    </Col>
                    <Col md={5} xxl={3} className="blog-post ps-md-3 ps-xxl-4">
                        <article className="portfolio__text position-sticky">
                            <h2>{t('portfolioDescription')}</h2>
                            <article dangerouslySetInnerHTML={{__html: t('portfolioProStekloText')}} />
                            <h3 className="mt-3 mt-xl-4 pt-xxl-1">{t('portfolioTechnologies')}</h3>
                            <ul dangerouslySetInnerHTML={{__html: t('portfolioProStekloText1')}} />
                        </article>
                    </Col>
                </Row>
                <LastProjects />
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPortfolioDataJson(filter: {name: {eq: "ProSteklo"}}) {
        nodes {
          images {
            childImageSharp {
              gatsbyImageData(formats: WEBP, placeholder: BLURRED, quality: 97)
            }
          }
        }
    }
  }
`;